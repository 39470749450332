<div *ngIf="showHeaderBox" class="header-box">
  <div class="selected-shipment">
    <div class="shipmentId">Selected: <b>{{showShipmentCode(selectedShipment)}}</b></div>
    <div class="actions">
      <button class="action-item" nz-button nzType="link" (click)="assignPOD()" [disabled]="!selectedShipment"
        [nzLoading]="isAssignPODInProcessing">Assign Selected Photo</button>
      <button class="action-item" nz-button nzType="link" (click)="assignGroupedPOD()" [disabled]="!selectedShipment"
        [nzLoading]="isAssignPODInProcessing">Assign Grouped Photos</button>
    </div>
  </div>
</div>
<div class="shipment-network" *ngIf="shipment" shipment-structure [shipment]="shipment" [children]="childrens"
  [refId]="'struture'+shipment.id" [compact]="true" [presentedShipment]="selectedShipment"
  [highlights]="[selectedShipment?.id]" [isShowActualTime]="isShowActualTime" (onSelectShipment)="onSelectShipment($event)"></div>